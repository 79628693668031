import { formatInTimeZone, isValid, parseDate } from "@product/scmp-sdk";
import { isSameDay, isSameMonth, isSameYear } from "date-fns";

export const formatPlusDatePair = (
  startDate: Date | number | string,
  endDate: Date | number | string,
) => {
  const format = (pairs: Array<[Date, string]>) =>
    pairs.map(([date, format]) => formatInTimeZone(date, format)).join("-");

  const parsedStartDate = parseDate(startDate);
  const parsedEndDate = parseDate(endDate);

  if (!isValid(parsedStartDate) || !isValid(parsedEndDate)) return;

  if (isSameDay(parsedStartDate, parsedEndDate)) {
    return format([[parsedStartDate, "d MMM yyyy"]]);
  }
  if (isSameMonth(parsedStartDate, parsedEndDate)) {
    return format([
      [parsedStartDate, "d"],
      [parsedEndDate, "d MMM yyyy"],
    ]);
  }
  if (isSameYear(parsedStartDate, parsedEndDate)) {
    return format([
      [parsedStartDate, "d MMM"],
      [parsedEndDate, "d MMM yyyy"],
    ]);
  }
  return format([
    [parsedStartDate, "d MMM yyyy"],
    [parsedEndDate, "d MMM yyyy"],
  ]);
};

export const removeQueryString = <T extends Record<string, unknown>>(
  query: T,
  remove: string[],
) => {
  const newQuery = { ...query };
  remove.forEach(key => delete newQuery[key]);
  return newQuery;
};
