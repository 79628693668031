export enum DefinedDateRange {
  LAST_3_MONTHS = "Last 3 Months",
  LAST_6_MONTHS = "Last 6 Months",
  NEXT_3_MONTHS = "Next 3 Months",
  NEXT_6_MONTHS = "Next 6 Months",
  THIS_MONTH = "This Month",
}

export const DefinedDateRanges = [
  DefinedDateRange.THIS_MONTH,
  DefinedDateRange.NEXT_3_MONTHS,
  DefinedDateRange.NEXT_6_MONTHS,
  DefinedDateRange.LAST_3_MONTHS,
  DefinedDateRange.LAST_6_MONTHS,
];
